import React, { FunctionComponent } from 'react';
import ContactUs from 'src/shared/components/contact/contact.component';
import webStyles from './testimonial-landing.module.scss';
import mobileStyles from './testimonial-landing-mobile.module.scss';
import useMobile from 'src/shared/hooks/use-mobile';
import VideoComponent from 'src/shared/components/videoComponent/videoComponent';
import { Helmet } from 'react-helmet';

const TestimonialLandingPage: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;
    const calendlyHeight = mobile ? '1100px' : '100%';

    return (
        <div className={styles.container}>
            {mobile ? <div style={{ height: '10px', width: '100%', background: '#011627' }}></div> : null}
            <div className={styles.content}>
                <div className={styles.pillars}>
                    <div>
                        <h2>Don&apos;t take our word for it...</h2>
                        <p>
                            Hear from Jason Eck Head Football Coach of the Idaho Vandals as he shares his experience
                            with Telemetry Sports Recruit and how it has helped his program throughout the recruiting
                            process.
                        </p>
                    </div>
                </div>
                <div className={styles.videoCalendar}>
                    <VideoComponent
                        url="https://s3.amazonaws.com//scratch.telemetry.fm/ar/telemetry-website/videos/ts-idaho-testimonial.mp4"
                        styleClass={styles.video}
                    />
                    <div className={styles.calendly}>
                        <div
                            className="calendly-inline-widget"
                            data-url="https://calendly.com/nate-telemetry/telemetry-sports-conversation"
                            style={{ width: '100%', height: calendlyHeight }}
                        ></div>
                        <Helmet>
                            <script
                                type="text/javascript"
                                src="https://assets.calendly.com/assets/external/widget.js"
                                async
                            ></script>
                        </Helmet>
                    </div>
                </div>
            </div>
            <ContactUs />
        </div>
    );
};

export default TestimonialLandingPage;
