import React, { FunctionComponent } from 'react';
import ContactUs from 'src/shared/components/contact/contact.component';
import VerticalHeader from 'src/shared/components/vertical-header/vertical-header.component';
// import { Image } from 'semantic-ui-react';

import webStyles from './broadcast.module.scss';
import mobileStyles from './broadcast-mobile.module.scss';
import useMobile from 'src/shared/hooks/use-mobile';
import useWindowDimensions from 'src/shared/hooks/use-window-dimensions';
import VideoComponent from 'src/shared/components/videoComponent/videoComponent';
// import broadcastDemo from '../../assets/images/broadcast-demo.png';

const Broadcast: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;
    const { width } = useWindowDimensions();

    return (
        <div className={styles.container}>
            <VerticalHeader title="Broadcast" tagline="INSERT RELEVANT BROADCAST TAGLINE/HEADER HERE" />
            <div className={styles.content}>
                {/* <Image src={broadcastDemo} className={styles.demo} /> */}
                <VideoComponent
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-broadcast.mp4"
                    styleClass={styles.video}
                />
                <div className={styles.descriptionContainer} style={{ width, maxWidth: width }}>
                    <div className={styles.description}>
                        <div className={styles.descriptionText}>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>
                                    Get a leg-up on other broadcasts.{' '}
                                </span>
                                Already established as a trusted, reliable, and responsive sports data technology
                                provider to over 30 NFL and NCAA&reg; organizations, Telemetry Sports new tool -
                                Telemetry Sports Broadcast - is bringing cutting-edge game data to broadcasters and
                                consumers offering an “insiders” lens to game statistics and insights. A visual replay
                                of a player&apos;s performance offered during game broadcasts brings a new dimension to
                                viewing a game and enhances and intensifies the audience&apos;s overall game experience.
                            </p>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>The lift is on us. </span>
                                With a turnkey process for gathering, packaging and then serving up game-time player
                                data, the end-product can be easily integrated into the broadcast commentary. It&apos;s
                                a game-changer for sponsorship advertising and for growing your viewing audience.
                                Telemetry Sports Broadcast will change the entertainment value of the game — and
                                broadcaster&apos;s bottom lines.
                            </p>
                        </div>
                        <div className={styles.graphic}>
                            <video
                                playsInline
                                autoPlay
                                loop
                                width={'100%'}
                                src="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-broadcast-sharp.mp4"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.pillars}>
                    <div>
                        <h2>efficient.</h2>
                        <p>
                            The turnaround time is impressive. From the moment the ball is snapped, and a player is
                            highlighted within a play, Telemetry Sports Broadcast&apos; cutting-edge sports data
                            technology goes to work to curate player performance data and bring it into replay mode
                            providing statistics from player speed to player location to competitive comparison insights
                            enhancing the viewers entertainment experience and sparking game interaction and engagement.
                        </p>
                    </div>
                    <div>
                        <h2>nimble.</h2>
                        <p>
                            We don&apos;t deliver our data sports technology product and disappear. Telemetry Sports
                            Broadcast is hands-on, nimble, and reactive to how consumers, audiences, and broadcasters
                            are digesting and using the statistics tool. It allows for flexibility in response to user
                            feedback and insights in the process of creating a product that is user friendly and
                            enhances the entertainment value of each unique game broadcast.
                        </p>
                    </div>
                    <div>
                        <h2>value-added.</h2>
                        <p>
                            Competition is the name of the game. The broadcast marketplace is a busy landscape and being
                            the first to introduce a new tool or product is always a bit daunting yet exhilarating. With
                            Telemetry Sports, already a trusted and reliable sports data technology provider to the NFL
                            and NCAA&reg;, our broadcast media tool offers broadcasters a leg-up on the competition
                            providing audiences a custom built, player performance statistical insight to enhance the
                            overall game viewing experience.
                        </p>
                    </div>
                </div>
            </div>
            <ContactUs />
        </div>
    );
};

export default Broadcast;
