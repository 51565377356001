import React, { FunctionComponent, useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import styles from './vertical-header.module.scss';

import pro from '../../../assets/images/verticals/pro.svg';
import college from '../../../assets/images/verticals/college.svg';
import broadcast from '../../../assets/images/verticals/broadcast.svg';
import recruit from '../../../assets/images/verticals/recruit.svg';
import ai from '../../../assets/images/verticals/ai.svg';

const VerticalHeader: FunctionComponent<{ title: string; tagline: string }> = ({ title, tagline }) => {
    useEffect(() => {
        document.title = 'Telemetry ' + title;
    }, [title]);

    const images: Record<string, any> = {
        pro,
        college,
        broadcast,
        recruit,
        ai,
    };

    return (
        <div className={styles.container}>
            <div className={styles.verticalImg}>
                <Image src={images[title.toLowerCase()]} />
            </div>
            <h1 style={{ display: 'none' }}>Telemetry Sports {title}</h1>
            <h2 style={{ display: 'none' }}>{tagline}</h2>
        </div>
    );
};

export default VerticalHeader;
