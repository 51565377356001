import React, { FunctionComponent } from 'react';

import webStyles from './ai-header.module.scss';
import mobileStyles from './ai-header-mobile.module.scss';
import useMobile from 'src/shared/hooks/use-mobile';
import ResponsiveVideoComponent from 'src/shared/components/responsive-video/responsive-video.component';

export const AIHeader: FunctionComponent<{ onNext(): void }> = ({ onNext }) => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;
    const playerContainer = React.useRef<HTMLDivElement>(null);

    return (
        <div className={styles.container}>
            <div style={{ height: 110, minHeight: 110, display: 'flex' }} />
            <div className={styles.content}>
                <div className={styles.headline}>
                    <div>Forging the future of sports</div>
                    <div>through AI-driven technologies.</div>
                </div>
                <div className={styles.video} ref={playerContainer}>
                    <ResponsiveVideoComponent
                        url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-predanalytics.mp4"
                        autoplay={true}
                    />
                </div>
                {!mobile && (
                    <div className={styles.learnMore} onClick={onNext}>
                        {/* <p>Learn More</p> */}
                        {/* <Icon
                            className={styles.learnMoreIcon}
                            name="angle down"
                            size={'large'}
                            bordered
                            style={{ color: '#18639d', borderColor: '#18639d' }}
                            circular
                        /> */}
                    </div>
                )}
            </div>
        </div>
    );
};
