import React, { FunctionComponent, useState } from 'react';
import { Image } from 'semantic-ui-react';
import useMobile from 'src/shared/hooks/use-mobile';
import employees, { Employee } from './employees';
import webStyles from './team.module.scss';
import mobileStyles from './team-mobile.module.scss';

const Team: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    const [activePerson, setActivePerson] = useState<Employee>();

    const person = (person: Employee) => {
        return (
            <div key={person.name} id={person.name} className={styles.person}>
                <div className={styles.card}>
                    <Image
                        id={person.name + ' img'}
                        src={person.headshot}
                        onClick={() => {
                            if (!mobile) setActivePerson(person);
                        }}
                    />
                    <h3>{person.name}</h3>
                    <h4>{person.title}</h4>
                </div>
            </div>
        );
    };

    const activePersonModal = () => {
        if (!activePerson) return null;
        return (
            <div
                className={styles.bio}
                style={{ display: activePerson ? 'flex' : 'none' }}
                onClick={() => setActivePerson(undefined)}
            >
                <div className={styles.bioContainer}>
                    <div
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <h2 style={{ marginBottom: 0 }}>{activePerson?.name}</h2>
                        <h4 style={{ marginTop: 0 }}>{activePerson.title}</h4>
                    </div>
                    <div className={styles.interview}>
                        <div className={styles.active}>
                            <div className={styles.title}>At Telemetry</div>
                            <div className={styles.info}>{activePerson.telemetry}</div>
                        </div>
                        <div className={styles.active}>
                            <div className={styles.title}>Education</div>
                            <div className={styles.info}>{activePerson.education}</div>
                        </div>
                        <div className={styles.active}>
                            <div className={styles.title}>About Me</div>
                            <div className={styles.info}>{activePerson.about}</div>
                        </div>
                        <div className={styles.active}>
                            <div className={styles.title}>Hobbies</div>
                            <div className={styles.info}>{activePerson.hobbies}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {activePersonModal()}
            <h2>OUR TEAM</h2>
            <div className={styles.team}>
                {mobile ? (
                    <>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(0, 2).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(2, 4).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(4, 6).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(6, 8).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(8, 10).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(10, 12).map(person)}
                            {/* <div className={styles.person} /> */}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(12).map(person)}
                            {/* <div className={styles.person} /> */}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(0, 5).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 900 }}>
                            {employees.slice(5, 10).map(person)}
                        </div>
                        <div className={styles.row} style={{ maxWidth: 720 }}>
                            {employees.slice(10).map(person)}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Team;
