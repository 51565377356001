import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import styles from './responsive-video.module.scss';

interface VideoComponentProps {
    url: string;
    autoplay?: boolean;
}

const ResponsiveVideoComponent: FunctionComponent<VideoComponentProps> = ({ url, autoplay = true }) => {
    const [loaded, setLoaded] = useState(false);
    const [playing, setPlaying] = useState(false);
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (loaded && autoplay && !playing) {
            setPlaying(true);
        }
    }, [loaded]);

    return (
        <div className={styles.wrapper} ref={container}>
            <ReactPlayer
                className={styles.player}
                url={url}
                controls
                playsinline
                playing={playing}
                onReady={() => setLoaded(true)}
                height={'100%'}
                width={'100%'}
            />
        </div>
    );
};

export default ResponsiveVideoComponent;
