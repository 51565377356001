import React, { FunctionComponent } from 'react';
import { Image } from 'semantic-ui-react';

import webStyles from './brand.module.scss';
import mobileStyles from './brand-mobile.module.scss';
import bgSrc from '../../../../assets/images/textures/triangle-dark.png';
import useMobile from 'src/shared/hooks/use-mobile';
import VideoComponent from 'src/shared/components/videoComponent/videoComponent';

const Brand: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    return (
        <div className={styles.container}>
            <Image src={bgSrc} className={styles.bg} />
            <div className={styles.children}>
                <div className={styles.headline}>
                    <div>Changing the Way Data and Technology</div>
                    <div>Influence Sports.</div>
                </div>
                <VideoComponent
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-brand.mp4"
                    styleClass={styles.video}
                />
                <div className={styles.content}>
                    <p>
                        <span>Built to Strengthen and Support</span>
                    </p>
                    <p>
                        Established as a trusted, respected and responsive leader in the professional sports data
                        technology arena, Telemetry Sports creates custom-built products committed to supporting sports
                        organization&apos;s successful outcomes. We&apos;re providing unique, efficient, and flexible
                        sports data solutions to strengthen everyday team, player, practice and recruitment processes.
                        From professional sports organizations to individual sport savvy consumers, we remain diligent
                        to provide customizable AI-driven sports data technology adapted to fit team and user needs.
                    </p>
                </div>
                <div className={styles.pillars}>
                    <div className={styles.pillar}>
                        <h2>trusted.</h2>
                        <p>Sports data technology custom built for success.</p>
                    </div>
                    <div className={styles.pillar}>
                        <h2>efficient.</h2>
                        <p>Streamlining the process with AI-driven technologies.</p>
                    </div>
                    <div className={styles.pillar}>
                        <h2>responsive.</h2>
                        <p>On call: ready to explore and create.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Brand;
