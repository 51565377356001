import React, { FunctionComponent } from 'react';
import { Image } from 'semantic-ui-react';
import useMobile from 'src/shared/hooks/use-mobile';
import webStyles from './history.module.scss';
import mobileStyles from './history-mobile.module.scss';
import timeline from '../../../../assets/images/timeline.svg';
import verticalTimeline from '../../../../assets/images/timeline-vertical.svg';

const History: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    return (
        <div className={styles.container}>
            <div className={styles.infoSection}>
                <h2>OUR HISTORY</h2>
                <p>
                    Since the introduction of player tracking chips in 2016–2017, we recognized a unique opportunity to
                    capture this data and give our partners a competitive edge—an approach we&apos;ve embraced ever
                    since. As we expanded in the NFL, we applied computer vision to deliver the same high-quality data
                    to the college game. Our team of experts continually seeks innovative ways to leverage AI
                    capabilities, helping teams gain an edge in the margins.
                </p>
            </div>
            <Image src={mobile ? verticalTimeline : timeline} className={styles.timeline} />
        </div>
    );
};

export default History;
