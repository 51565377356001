import React, { FunctionComponent, useEffect } from 'react';
import Background from './components/background/background.component';
import AboutPillars from './components/pillars/pillars.component';

import webStyles from './about.module.scss';
import mobileStyles from './about-mobile.module.scss';

import Team from './components/team/team.component';
import ContactUs from 'src/shared/components/contact/contact.component';
import header from '../../assets/images/textures/path-texture-large.svg';
import { Image } from 'semantic-ui-react';
import useMobile from 'src/shared/hooks/use-mobile';
import History from './components/history/history.component';

const About: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    useEffect(() => {
        document.title = 'Telemetry | About';
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.children}>
                <div className={styles.header}>
                    <h2>CHANGING THE WAY DATA AND TECHNOLOGY INFLUENCE THE SPORTS EXPERIENCE</h2>
                    {mobile ? (
                        <div className={styles.partners}>
                            <div className={styles.nflDiv}>
                                <h1>25+</h1>
                                <h2>NFL PARTNERS</h2>
                            </div>
                            <div className={styles.ncaaDiv}>
                                <h1>20+</h1>
                                <h2>CFB PARTNERS</h2>
                            </div>
                        </div>
                    ) : null}
                    <Image src={header} />
                </div>
                <Background />
                <AboutPillars />
                <Team />
                <History />
                <ContactUs />
                {/* <div style={{ height: 800, position: 'relative', overflow: 'hidden' }}>
                <Image src={wave} className={styles.dots} />
            </div>
            <div style={{ height: 800, position: 'relative', overflow: 'hidden' }}>
                <Image src={dots} className={styles.dots} />
            </div>
            <div style={{ height: 800, position: 'relative', overflow: 'hidden' }}>
                <Image src={wordmark} className={styles.wordmark} />
            </div> */}
            </div>
        </div>
    );
};

export default About;
