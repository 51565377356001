import React, { FunctionComponent, useState, useEffect } from 'react';
import webStyles from './contact.module.scss';
import mobileStyles from './contact-mobile.module.scss';
import { Image, Button, Input } from 'semantic-ui-react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import bg from '../../assets/images/textures/triangle-dark.png';
import circle from '../../assets/images/circle.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import useMobile from 'src/shared/hooks/use-mobile';

const animatedComponents = makeAnimated();

const CustomForm: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;
    const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [team, setTeam] = useState('');
    const [phone, setPhone] = useState('');
    const [products, setProducts] = useState<string[]>([]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!email || !firstName || !lastName || email.indexOf('@') === -1) {
            setStatus('error');
            setMessage('Please fill in all required fields');
            return;
        }

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=00Dal00000OHI82`;
        form.target = 'hidden_iframe';

        const fields = {
            first_name: firstName,
            last_name: lastName,
            email,
            phone,
            company: team,
            lead_source: 'Web',
            industry: `Interested Products: ${products.join(', ')}`,
            retURL: window.location.href,
            debug: '1',
            debugEmail: 'clay@telemetry.fm',
            DuplicateCheck: '1',
            DuplicateCheckFields: 'email,company',
        };

        Object.entries(fields).forEach(([name, value]) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = name;
            input.value = value as string;
            form.appendChild(input);
        });

        const iframe = document.createElement('iframe');
        iframe.name = 'hidden_iframe';
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);

        setStatus('success');
        setMessage('Thank you for your submission!');
        clearFields();

        setTimeout(() => {
            document.body.removeChild(iframe);
        }, 1000);
    };

    useEffect(() => {
        if (status === 'success') clearFields();
    }, [status]);

    const options = [
        { value: 'PRO', label: 'Pro' },
        { value: 'COLLEGE', label: 'College' },
        { value: 'RECRUIT', label: 'Recruit' },
        { value: 'BROADCAST', label: 'Broadcast' },
    ];

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setTeam('');
        setPhone('');
        setProducts([]);
    };

    const formatPhoneNumber = (input: string): string => {
        const cleaned = input.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return input;
    };

    const handleInputChange = (value: string) => {
        const numericInput = value.replace(/\D/g, '');
        const formattedValue = formatPhoneNumber(numericInput);
        setPhone(formattedValue);
    };

    return (
        <form
            className={styles.form}
            style={{
                justifyContent: status !== 'success' ? 'space-between' : 'center',
                alignItems: status !== 'success' ? 'flex-start' : 'center',
            }}
        >
            <div style={{ width: '100%' }}>
                <h3 className={styles.title}>
                    {status === 'success' ? '- We look forward to getting in contact with you -' : null}
                </h3>
                {status === 'sending' && <div className={styles.success}>sending...</div>}
                {status === 'error' && (
                    <div className="mc__alert mc__alert--error" dangerouslySetInnerHTML={{ __html: message }} />
                )}
                {status === 'success' && (
                    <div className={styles.success} dangerouslySetInnerHTML={{ __html: message }} />
                )}
                {status !== 'success' ? (
                    <>
                        <h3>First Name</h3>
                        <Input
                            className={styles.input}
                            onChange={(_, { value }) => setFirstName(value)}
                            type="text"
                            value={firstName}
                            isRequired
                        />
                        <h3>Last Name</h3>
                        <Input
                            className={styles.input}
                            onChange={(_, { value }) => setLastName(value)}
                            type="text"
                            value={lastName}
                            isRequired
                        />
                        <h3>Phone Number</h3>
                        <Input
                            className={styles.input}
                            onChange={(_, { value }) => handleInputChange(value)}
                            type="tel"
                            value={phone}
                        />
                        <h3>Email Address</h3>
                        <Input
                            className={styles.input}
                            onChange={(_, { value }) => setEmail(value)}
                            type="email"
                            value={email}
                            isRequired
                        />
                        <h3>Team/Organization</h3>
                        <Input
                            className={styles.input}
                            onChange={(_, { value }) => setTeam(value)}
                            type="text"
                            value={team}
                            isRequired
                        />
                        <h3 style={{ marginBottom: 6 }}>What are you interested in?</h3>
                        <Select
                            className={styles.multiselect}
                            options={options}
                            isMulti
                            menuPlacement={mobile ? 'top' : 'bottom'}
                            onChange={(n) => setProducts(n.map((p: any) => p.value))}
                            components={animatedComponents}
                            closeMenuOnSelect={false}
                        />
                    </>
                ) : null}
            </div>
            {status === 'success' ? null : (
                <Button onClick={handleSubmit} className={styles.submit}>
                    Submit
                </Button>
            )}
        </form>
    );
};

const Contact: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    return (
        <div className={styles.container}>
            <Image src={bg} className={styles.bg} />
            <div className={styles.children}>
                <h1>Contact Us</h1>
                <h3>Any questions or looking for a demo? Just send us a message!</h3>
                <div className={styles.contactContainer}>
                    <div className={styles.digitalCard}>
                        <div className={styles.content}>
                            <h2>Contact Information</h2>
                            <p>Fill up the form and our Team will get back to you shortly!</p>
                        </div>
                        <div className={styles.contactCard}>
                            <Image src={require('../../assets/images/nate-headshot.png')} />
                            <div>
                                <h2>Nate Jahn</h2>
                                <h3>Head of Business Development & Strategy</h3>
                                <div className={styles.detail}>
                                    <FontAwesomeIcon icon={faPhone} color="#2f9ff5" size="lg" />
                                    <p>(812) 607-0354</p>
                                </div>
                                <div className={styles.detail}>
                                    <FontAwesomeIcon icon={faEnvelope} color="#2f9ff5" size="lg" />
                                    <p>
                                        <a href="mailto:nate@telemetry.fm">nate@telemetry.fm</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: 'absolute', bottom: '2em', left: '2em' }}>
                            {/* <a href="https://www.facebook.com/TelemetrySports" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFacebook} color="#fff" size="lg" className={styles.social} />
                            </a> */}
                            <a href="https://twitter.com/telemetrysports" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faTwitter} color="#fff" size="lg" className={styles.social} />
                            </a>
                            <a href="https://www.instagram.com/telemetrysports/?hl=en" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faInstagram} color="#fff" size="lg" className={styles.social} />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/telemetry-sports/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faLinkedin} color="#fff" size="lg" className={styles.social} />
                            </a>
                            <a href="https://www.tiktok.com/@telemetrysports" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faTiktok} color="#fff" size="lg" className={styles.social} />
                            </a>
                        </div>
                        <Image src={circle} className={styles.circle} />
                        <Image src={circle} className={styles.circleTwo} />
                    </div>
                    <div className={styles.formContainer}>
                        <CustomForm />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
