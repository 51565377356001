import React, { FunctionComponent } from 'react';
import { Button, Image } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import ReactGA from 'react-ga4';

import tsVertical from '../../../assets/images/ts-vertical-main.png';
import webStyles from './footer.module.scss';
import mobileStyles from './foooter-mobile.module.scss';

import useMobile from 'src/shared/hooks/use-mobile';

const Footer: FunctionComponent = () => {
    const isMobile = useMobile();

    const styles = isMobile ? mobileStyles : webStyles;

    const recordEvent = () => {
        ReactGA.event({
            category: 'Clicks',
            action: 'Contact',
            label: 'footer',
        });
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.verticalContainer}>
                    <Image src={tsVertical} />
                    <div style={{ marginTop: 30 }}>
                        <a href="https://twitter.com/telemetrysports" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faTwitter} color="#fff" size="2x" className={styles.social} />
                        </a>
                        {/* <a href="https://www.facebook.com/TelemetrySports" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebook} color="#fff" size="2x" className={styles.social} />
                        </a> */}
                        <a href="https://www.instagram.com/telemetrysports/?hl=en" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} color="#fff" size="2x" className={styles.social} />
                        </a>
                        <a href="https://www.linkedin.com/company/telemetry-sports/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} color="#fff" size="2x" className={styles.social} />
                        </a>
                        <a href="https://www.tiktok.com/@telemetrysports" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faTiktok} color="#fff" size="2x" className={styles.social} />
                        </a>
                    </div>
                    {isMobile ? (
                        <a href="/contact">
                            <Button className={styles.mobileContact} onClick={recordEvent}>
                                CONTACT US
                            </Button>
                        </a>
                    ) : null}
                </div>
                {!isMobile ? (
                    <>
                        <div className={styles.productContainer}>
                            <div className={styles.productHeader}>PRODUCTS</div>
                            <div className={styles.product}>
                                <a href="/pro">PRO</a>
                            </div>
                            <div className={styles.product}>
                                <a href="/college">COLLEGE</a>
                            </div>
                            <div className={styles.product}>
                                <a href="/recruit">RECRUIT</a>
                            </div>
                            <div className={styles.product}>
                                <a href="/broadcast">BROADCAST</a>
                            </div>
                        </div>
                        <div className={styles.contactContainer}>
                            <h2>Ready for a demo?</h2>
                            <p>Let&apos;s schedule some time and see how we can customize our tools to help you!</p>
                            <a href="/contact">
                                <Button className={styles.contact}>CONTACT US</Button>
                            </a>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default Footer;
