import React, { FunctionComponent, useEffect } from 'react';

import useMobile from 'src/shared/hooks/use-mobile';
import webStyles from './marquee.module.scss';

import mobileStyles from './marquee-mobile.module.scss';
import { Image } from 'semantic-ui-react';
import nflLogo from 'src/assets/images/pro/nfl.png';
import afcLogo from 'src/assets/images/pro/afc.png';
import nfcLogo from 'src/assets/images/pro/nfc.png';

import secLogo from 'src/assets/images/college/sec.png';
import big10Logo from 'src/assets/images/college/big10.png';
import big12Logo from 'src/assets/images/college/big12.png';
import accLogo from 'src/assets/images/college/acc.png';
import americanLogo from 'src/assets/images/college/american.png';
import sbcLogo from 'src/assets/images/college/sbc.png';
import gmacLogo from 'src/assets/images/college/gmac.png';
import hoacLogo from 'src/assets/images/college/hoac.png';

const Marquee: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    useEffect(() => {
        document.title = 'Telemetry | About';
    }, []);

    return (
        <div className={styles.marquee}>
            <div className={styles.marqueeContent}>
                <Image src={nflLogo} className={styles.logos} />
                <Image src={nfcLogo} className={styles.logos} />
                <Image src={afcLogo} className={styles.logos} />
                <Image src={secLogo} className={styles.logos} />
                <Image src={big10Logo} className={styles.logos} />
                <Image src={big12Logo} className={styles.logos} />
                <Image src={accLogo} className={styles.logos} />
                <Image src={americanLogo} className={styles.logos} />
                <Image src={sbcLogo} className={styles.logos} />
                <Image src={hoacLogo} className={styles.logos} />
                <Image src={gmacLogo} className={styles.logos} />

                <Image src={nflLogo} className={styles.logos} />
                <Image src={nfcLogo} className={styles.logos} />
                <Image src={afcLogo} className={styles.logos} />
                <Image src={secLogo} className={styles.logos} />
                <Image src={big10Logo} className={styles.logos} />
                <Image src={big12Logo} className={styles.logos} />
                <Image src={accLogo} className={styles.logos} />
                <Image src={americanLogo} className={styles.logos} />
                <Image src={sbcLogo} className={styles.logos} />
                <Image src={hoacLogo} className={styles.logos} />
                <Image src={gmacLogo} className={styles.logos} />
            </div>
        </div>
    );
};

export default Marquee;
