import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import useIntersection from 'src/shared/hooks/use-intersection';

interface VideoComponentProps {
    url: string;
    styleClass: string;
    autoplay?: boolean;
    height?: number;
    thumbnail?: any;
}

const VideoComponent: FunctionComponent<VideoComponentProps> = ({
    url,
    styleClass,
    thumbnail,
    height,
    autoplay = true,
}) => {
    const [loaded, setLoaded] = useState(false);
    const [playing, setPlaying] = useState(false);
    const video = useRef<ReactPlayer>(null);
    const container = useRef<HTMLDivElement>(null);
    const videoOnScreen = useIntersection(container, '-300px');

    useEffect(() => {
        if (loaded && videoOnScreen && autoplay && !playing) {
            setPlaying(true);
        }
    }, [loaded, videoOnScreen]);

    return (
        <div className={styleClass} ref={container}>
            <ReactPlayer
                url={url}
                controls
                playsinline
                playing={playing}
                onReady={() => setLoaded(true)}
                ref={video}
                light={thumbnail}
                height={height ?? '100%'}
                width={'100%'}
            />
        </div>
    );
};

export default VideoComponent;
