import React, { FunctionComponent } from 'react';
import './App.css';
import { RouteObject, useRoutes } from 'react-router-dom';
import ApplicationLayout from './layouts/application-layout.component';
import NAVIGATION_ROUTES from './config/routes';
import { ParallaxProvider } from 'react-scroll-parallax';

import ReactGA from 'react-ga4';
ReactGA.initialize([{ trackingId: 'G-NPEFJPB3Q8' }]);

export const getRoutes = () => {
    const fullRoutes: RouteObject = { children: NAVIGATION_ROUTES };
    const element = useRoutes([fullRoutes]);

    return element;
};

const App: FunctionComponent = () => {
    const element = getRoutes();

    return (
        <div>
            <ParallaxProvider>
                <ApplicationLayout>{element}</ApplicationLayout>
            </ParallaxProvider>
        </div>
    );
};

export default App;
