import React, { FunctionComponent } from 'react';
import ContactUs from 'src/shared/components/contact/contact.component';

import webStyles from './ai.module.scss';
import mobileStyles from './ai-mobile.module.scss';
import useMobile from 'src/shared/hooks/use-mobile';
import { AIHeader } from './components/header/ai-header.component';
// import { AIBoxes } from './components/box/ai-boxes.component';
import Footer from 'src/layouts/components/footer/footer.component';

export const AIPage: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const onNext = () => containerRef.current?.scrollTo({ top: window.innerHeight, behavior: 'smooth' });

    return (
        <div className={styles.container} ref={containerRef}>
            {!mobile ? (
                <>
                    <AIHeader onNext={onNext} />
                    {/* <AIBoxes /> */}
                    <ContactUs />
                    <Footer />
                </>
            ) : (
                <>
                    <AIHeader onNext={onNext} />
                    <ContactUs />
                    <Footer />
                </>
            )}
        </div>
    );
};
