import React, { FunctionComponent } from 'react';
import { Image } from 'semantic-ui-react';

import collaboration from '../../../../assets/images/ts-about-us-right.png';
import xo from '../../../../assets/images/icons/xo.svg';
import shield from '../../../../assets/images/icons/shield.svg';
import trophy from '../../../../assets/images/icons/trophy.svg';
import useMobile from 'src/shared/hooks/use-mobile';

import webStyles from './pillars.module.scss';
import mobileStyles from './pillars-mobile.module.scss';

const AboutPillars: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;
    return (
        <div className={styles.container}>
            <div className={styles.children}>
                <div className={styles.collaboration}>
                    <Image src={collaboration} className={styles.collabImg} />
                </div>
                <div className={styles.pillarContainer}>
                    <div className={styles.pillar}>
                        <Image src={shield} />
                        <p>
                            Committed to the ethical treatment of shared organizational information and data technology
                            building a foundation of open communication and honest goal-setting deliverables.
                        </p>
                    </div>
                    <div className={styles.pillar}>
                        <Image src={trophy} />
                        <p>
                            Data=knowledge=power=efficiency=winning. Telemetry Sports is dedicated to connecting
                            organizations with trusted, efficient flexible sports data technology to save time and
                            produce successful and winning results.
                        </p>
                    </div>
                    <div className={styles.pillar}>
                        <Image src={xo} className={styles.xo} />
                        <p>
                            Inquisitive by nature and deliberate in efficient execution, Telemetry Sports is always in
                            exploration mode searching for sports data technology solutions to organizational pain
                            points and evolving needs offering flexible end-user options.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutPillars;
