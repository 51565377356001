import React from 'react';
import { FunctionComponent } from 'react';
import { Image } from 'semantic-ui-react';
import webStyles from './client-logo.module.scss';
import mobileStyles from './client-logo-mobile.module.scss';
import logo from '../../../assets/images/ts-wordmark.svg';
import whiteLogo from '../../../assets/images/ts-wordmark-white.svg';

import mobileLogo from '../../../assets/images/ts-brandmark-color.svg';
import mobileWhiteLogo from '../../../assets/images/ts-brandmark.svg';
import useMobile from 'src/shared/hooks/use-mobile';

const Logo: FunctionComponent<{ color?: 'white' | 'full' }> = ({ color = 'full' }) => {
    const mobile = useMobile();
    const tgtWeb = color === 'white' ? whiteLogo : logo;
    const tgtMobile = color === 'white' ? mobileWhiteLogo : mobileLogo;
    const tgtSrc = mobile ? tgtMobile : tgtWeb;

    const styles = mobile ? mobileStyles : webStyles;
    return <Image size="mini" className={styles.logo} src={tgtSrc} as="a" href="/" />;
};

export default Logo;
