import { useState, useLayoutEffect } from 'react';

const useIntersection = (element: any, rootMargin: any) => {
    const [isVisible, setState] = useState(false);

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            },
            { rootMargin },
        );

        element.current && observer.observe(element.current);

        return () => observer.unobserve(element.current);
    }, []);

    return isVisible;
};

export default useIntersection;
