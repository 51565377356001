import React, { FunctionComponent, useCallback } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useRoutes } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import NAVIGATION_ROUTES from 'src/config/routes';
import IRoute from 'src/shared/models/interfaces/iroute';
import Logo from '../client-logo/client-logo.component';
import ReactGA from 'react-ga4';

import styles from './mobile-menu.module.scss';

const MobileMenu: FunctionComponent<{ light: boolean }> = ({ light }) => {
    const route = useRoutes(NAVIGATION_ROUTES);

    const active = route?.props.match.route.path;

    const renderNavItems = useCallback(() => {
        const navItems = NAVIGATION_ROUTES.filter((r: IRoute) => r.isNavItem && !r.isWebOnly);
        return (
            navItems &&
            navItems.map((n: IRoute, index: number) => {
                return (
                    <a
                        key={index}
                        id={n.path}
                        className="menu-item"
                        href={n.path}
                        style={{ color: n.path === active ? '#2f9ff5' : 'whitesmoke' }}
                    >
                        {n.name}
                    </a>
                );
            })
        );
    }, [NAVIGATION_ROUTES]);

    const recordEvent = () => {
        ReactGA.event({
            category: 'Clicks',
            action: 'Contact',
            label: 'header',
        });
    };

    return (
        <div className={!light ? styles.navContainer : styles.navContainerLight}>
            <Logo color={!light ? 'white' : 'full'} />
            <Menu right burgerBarClassName={light ? 'bm-burger-bars-light' : 'bm-burger-bars'}>
                <a
                    key="home"
                    id="home"
                    className="menu-item"
                    href="/"
                    style={{ color: '' === active ? '#2f9ff5' : 'whitesmoke' }}
                >
                    Home
                </a>
                {renderNavItems()}
                <a key="contact" id="contact" href="/contact" className={styles.contactParent}>
                    <Button className={styles.contact} onClick={recordEvent}>
                        Contact Us
                    </Button>
                </a>
            </Menu>
        </div>
    );
};

export default MobileMenu;
