import React, { FunctionComponent, useState } from 'react';
import { Button, Input, Image } from 'semantic-ui-react';
import styles from './contact.module.scss';
import wave from '../../../assets/images/textures/wave-dots.png';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const ContactForm: FunctionComponent = () => {
    const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [team, setTeam] = useState('');
    const [phone, setPhone] = useState('');
    const [products, setProducts] = useState<string[]>([]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!email || !firstName || !lastName || email.indexOf('@') === -1) {
            setStatus('error');
            setMessage('Please fill in all required fields');
            return;
        }

        setStatus('sending');

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=00Dal00000OHI82`;
        form.target = 'hidden_iframe';

        const fields = {
            first_name: firstName,
            last_name: lastName,
            email,
            phone,
            company: team,
            lead_source: 'Web',
            industry: `Interested Products: ${products.join(', ')}`,
            retURL: window.location.href,
            debug: '1',
            debugEmail: 'clay@telemetry.fm',
            DuplicateCheck: '1',
            DuplicateCheckFields: 'email,company',
        };

        Object.entries(fields).forEach(([name, value]) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = name;
            input.value = value as string;
            form.appendChild(input);
        });

        const iframe = document.createElement('iframe');
        iframe.name = 'hidden_iframe';
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);

        setStatus('success');
        setMessage('Thank you for your message. We will be in touch soon!');
        clearFields();

        setTimeout(() => {
            document.body.removeChild(iframe);
        }, 1000);
    };

    const options = [
        { value: 'PRO', label: 'Pro' },
        { value: 'COLLEGE', label: 'College' },
        { value: 'RECRUIT', label: 'Recruit' },
        { value: 'BROADCAST', label: 'Broadcast' },
    ];

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setTeam('');
        setProducts([]);
        setPhone('');
    };

    const formatPhoneNumber = (input: string): string => {
        const cleaned = input.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return input;
    };

    const handleInputChange = (value: string) => {
        const numericInput = value.replace(/\D/g, '');
        const formattedValue = formatPhoneNumber(numericInput);
        setPhone(formattedValue);
    };

    return (
        <form className={styles.form}>
            <div>
                <h3 className={styles.header}>CONTACT</h3>
                <hr style={{ height: 3, backgroundColor: 'white', width: 70 }} />
                {status !== 'success' ? <p className={styles.demo}>Interested in a demo?</p> : null}
            </div>
            <h3 className={styles.title}>
                {status === 'success' ? '- We look forward to getting in contact with you -' : null}
            </h3>
            {status === 'sending' && <div className={styles.success}>sending...</div>}
            {status === 'error' && (
                <div className="mc__alert mc__alert--error" dangerouslySetInnerHTML={{ __html: message.toString() }} />
            )}
            {status === 'success' && <div className={styles.success} dangerouslySetInnerHTML={{ __html: message }} />}
            {status !== 'success' ? (
                <>
                    <Input
                        className={styles.input}
                        onChange={(_, { value }) => setFirstName(value)}
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={firstName}
                        placeholder="* First Name"
                        isRequired
                    />
                    <Input
                        className={styles.input}
                        onChange={(_, { value }) => setLastName(value)}
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={lastName}
                        placeholder="* Last Name"
                        isRequired
                    />
                    <Input
                        className={styles.input}
                        onChange={(_, { value }) => setEmail(value)}
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="* Email Address"
                        isRequired
                    />
                    <Input
                        className={styles.input}
                        onChange={(_, { value }) => handleInputChange(value)}
                        type="tel"
                        id="phone"
                        name="phone"
                        value={phone}
                        placeholder="Phone Number"
                    />
                    <Input
                        className={styles.input}
                        onChange={(_, { value }) => setTeam(value)}
                        type="text"
                        id="company"
                        name="company"
                        value={team}
                        placeholder="* Team/Organization"
                        isRequired
                    />
                    <Select
                        options={options}
                        isMulti
                        onChange={(n) => setProducts(n.map((p: any) => p.value))}
                        components={animatedComponents}
                        placeholder="* Which products are you interested in?"
                        className="react-select-container"
                        classNamePrefix={'react-select'}
                        isSearchable={false}
                        styles={{
                            singleValue: ({ ...styles }) => ({
                                ...styles,
                                maxWidth: 120,
                            }),
                        }}
                        menuPlacement="top"
                        closeMenuOnSelect={false}
                    />
                </>
            ) : null}
            {status === 'success' ? null : (
                <Button onClick={handleSubmit} className={styles.submit}>
                    Submit
                </Button>
            )}
        </form>
    );
};

const ContactUs: FunctionComponent = () => {
    return (
        <div className={styles.container}>
            <div className="mc__form-container">
                <ContactForm />
            </div>
            <Image src={wave} className={styles.dots} />
        </div>
    );
};

export default ContactUs;
