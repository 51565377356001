import React, { FunctionComponent } from 'react';
import webStyles from './background.module.scss';
import mobileStyles from './background-mobile.module.scss';

import useMobile from 'src/shared/hooks/use-mobile';
import Marquee from 'src/shared/components/marquee/marquee.component';

const Background: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    return (
        <div className={styles.container}>
            <div className={styles.graphic}>
                {mobile ? (
                    <div className={styles.aboutHeader}>
                        <div className={styles.graphicHeader}>WHO WE ARE</div>
                    </div>
                ) : (
                    <div className={styles.aboutHeader}>
                        <div className={styles.nflFlipCard}>
                            <div className={styles.nflFlipCardFront}>
                                <h1>25+</h1>
                                <h2>NFL PARTNERS</h2>
                            </div>
                            <div className={styles.nflFlipCardBack}>
                                <a href="/pro">
                                    <div style={{ color: 'black' }}>
                                        You can trust us. Over 25 NFL organizations rely on our customized sports data
                                        technology to inform decision-making and provide solutions to evolving team
                                        needs focused on winning results.
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className={styles.graphicHeader}>WHO WE ARE</div>
                        <div className={styles.ncaaFlipCard}>
                            <div className={styles.ncaaFlipCardFront}>
                                <h1>20+</h1>
                                <h2>CFB PARTNERS</h2>
                            </div>
                            <div className={styles.ncaaFlipCardBack}>
                                <a href="/college">
                                    <div style={{ color: 'black' }}>
                                        We&apos;re experts at every level of the game. From SEC to FCS to NAIA we are an
                                        extension of over 20 college football programs, ensuring they have the tools
                                        they need to succeed each week.
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.backgroundDiv}>
                    <p className={styles.graphicP}>
                        We aren&apos;t “one size fits all” in our approach. We understand your organization is unique
                        with individual needs and goals. Telemetry Sports works as an extension of your staff and your
                        process. By automating the routine tasks, we put minutes—even hours—back on the clock allowing
                        you to focus on what really matters.
                    </p>
                    <p className={styles.graphicP}>
                        At Telemetry Sports, we are at the forefront of Artificial Intelligence (AI) innovation,
                        redefining the game of football through our data-driven approach. By utilizing our proprietary
                        computer vision technology and advanced machine learning algorithms, we&apos;re revolutionizing
                        data processes for our clients. With Telemetry Sports, you&apos;re not just getting data-backed
                        insights; you&apos;re gaining a competitive edge to help you win in the margins.
                    </p>
                </div>
            </div>
            {!mobile && <Marquee />}
        </div>
    );
};

export default Background;
