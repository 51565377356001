import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import useMobile from 'src/shared/hooks/use-mobile';
import ContactUs from 'src/shared/components/contact/contact.component';
import Verticles from './components/verticles/verticles.component';
import webStyles from './home.module.scss';
import mobileStyles from './home-mobile.module.scss';
import Brand from './components/brand/brand.component';

const BACKGROUND = {
    mobile: '#010F1D',
    mac: '#01192b',
    windows: '#02182B',
};

const Home: FunctionComponent = () => {
    const loop = useRef<HTMLVideoElement>(null);
    const [done, setDone] = useState(false);
    const [bg, setBg] = useState(BACKGROUND.windows);
    const mobile = useMobile();

    useEffect(() => {
        if (mobile) setBg(BACKGROUND.mobile);
        else {
            const platform = navigator?.platform || 'unknown';
            const isMac = platform.toUpperCase().indexOf('MAC') >= 0;
            if (isMac) setBg(BACKGROUND.mac);
            else setBg(BACKGROUND.windows);
        }
    }, [mobile]);

    useEffect(() => {
        setTimeout(() => {
            setDone(true);
        }, 2000);
    }, []);

    const styles = mobile ? mobileStyles : webStyles;

    return (
        <div className={styles.container} style={{ backgroundColor: bg }}>
            <div className={styles.hero}>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        height: '100vh',
                        width: '100vw',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <video
                        autoPlay
                        muted
                        id="video"
                        width={mobile ? '100%' : '85%'}
                        playsInline
                        src={
                            mobile
                                ? 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/mobile/ts-hero.mp4'
                                : 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-hero.mp4'
                        }
                        onEnded={() => {
                            loop.current?.style.setProperty('display', 'block');
                            loop.current?.play();
                            setDone(true);
                        }}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: mobile ? 'translate(-50%, -50%)' : 'translate(-50%, -50%) scale(1.2)',
                            zIndex: 1,
                            maxHeight: '100%',
                            objectFit: 'contain',
                            clipPath: 'inset(3px 3px)',
                        }}
                    />
                    <video
                        id="hero-loop"
                        ref={loop}
                        loop
                        muted
                        width={mobile ? '100%' : '85%'}
                        playsInline
                        src={
                            mobile
                                ? 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/mobile/ts-hero-loop.mp4'
                                : 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-hero-loop.mp4'
                        }
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: mobile ? 'translate(-50%, -50%)' : 'translate(-50%, -50%) scale(1.2)',
                            zIndex: 1,
                            maxHeight: '100%',
                            objectFit: 'contain',
                            clipPath: 'inset(10px 10px)',
                            display: 'none',
                        }}
                    />
                </div>
                <div
                    className={styles.headerTop}
                    style={{ opacity: done || mobile ? 0.7 : 0, transition: 'opacity 2s ease-out' }}
                >
                    <span>trusted.</span>
                    <span style={{ marginLeft: mobile ? 5 : 15 }}>efficient.</span>
                    <span style={{ marginLeft: mobile ? 5 : 15 }}>responsive.</span>
                </div>
                <div
                    className={styles.headerBottom}
                    style={{ opacity: done || mobile ? 1 : 0, transition: 'opacity 2s ease-out' }}
                >
                    <div>Forging the future of sports</div>
                    <div>through AI-driven technologies.</div>
                </div>
            </div>
            <Verticles style={{ backgroundColor: bg }} />
            <Brand />
            <ContactUs />
        </div>
    );
};

export default Home;
