export interface Employee {
    name: string;
    title: string;
    telemetry: string;
    education: string;
    about: string;
    hobbies: string;
    headshot: string;
}

const employees: Employee[] = [
    {
        name: 'Jeremy Hochstedler',
        title: 'Founder & CEO',
        telemetry: 'Founded Telemetry Sports in 2016 - a leader and innovator in the sports data technology landscape.',
        education: 'Rose-Hulman Institute of Technology, University of Notre Dame, Stanford University, & MIT',
        about: "My goal is to understand an organization's needs and work with our team to develop solutions that streamline, support, and advance their internal processes at the highest levels in sports.",
        hobbies:
            'Spending time with my family is a priority and I enjoy sports of any kind, fishing, boating, and skiing.',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/jeremyh.png',
    },
    {
        name: 'Nathan Jahn',
        title: 'Head of Business Development & Strategy',
        telemetry: 'Joined the Telemetry Sports team in 2020.',
        education: 'DePauw University',
        about: "Playing sports my entire life has taught me to work hard and be competitive in everything that I do. At Telemetry Sports I use that competitive drive to provide the best products and support to football teams to help them think uniquely and to 'win in the margins.'",
        hobbies: "Big Indiana Pacers fan and my last good read was Simon Sinek's Start with Why.",
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/natej.png',
    },
    {
        name: 'Andrew Rodgers',
        title: 'Head of Product & Operations',
        telemetry: 'Joined the Telemetry Sports team in 2021.',
        education: 'Wabash College',
        about: "My experiences at Wabash, both in the classroom and on the baseball field, taught me to value effective communication. In my role with Telemetry Sports, I'm able to put those valuable tools to work to grow in the technical space where communication is often undervalued. Being able to work with people who are incredibly passionate about sports and pushing the boundary of how we digest and explore its data is about as rewarding as it gets.",
        hobbies: 'I enjoy eSports, specifically League of Legends. Cloud9 is my favorite organization.',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/andrewr.png',
    },
    {
        name: 'Chris Moyer',
        title: 'Head of Product Development',
        telemetry: 'Joined Telemetry in 2021.',
        education: 'DePauw University',
        about: 'My goal is to develop sports technology that is accessible, relevant and reliable.',
        hobbies: 'I enjoy snowboarding, surfing, and writing code.',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/chrism.png',
    },
    {
        name: 'Jordan Chipka',
        title: 'Head of Research & Development',
        telemetry: 'Joined the Telemetry Sports team in 2021.',
        education: 'Rose-Hulman Institute of Technology & Cornell University',
        about: 'My biggest work influence came in grad school at Cornell where I learned so much from my advisers, colleagues, and lab mates. Due to the nature of extended independent research I was forced to figure out difficult things on my own. I knew my work could have a real and immediate impact at Telemetry Sports because it is a small, fast-paced company where I could help build it into something special.',
        hobbies:
            'The “Numberphile” podcast is a favorite of mine, and I was a part of the Cinderella run by the Cornell club basketball team to win the 2015 NIRSA National Championship.',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/jordanc.png',
    },
    {
        name: 'Tyler Fuelling',
        title: 'Sr. Data Engineer',
        telemetry: 'Joined Telemetry Sports in 2021.',
        education: 'University of Michigan',
        about: 'Being a member and president of the Michigan Sports Analytics Society in college gave me the opportunity to meet other students who were interested in sport analytics as a career. Today, I work on consistently improving and augmenting the data that we provide to our clients and communicate with them to create custom data solutions. Combining my love of football and technology and analytics led me to a job with Telemetry Sports.',
        hobbies:
            'My favorite sport is basketball — specifically Michigan basketball. The last (and favorite) podcast I\'ve listened to is the "Andy Staples Show" on The Athletic (college football podcast). My favorite hobby is playing golf.',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/tylerf.png',
    },
    {
        name: 'Amy Hochstedler',
        title: 'Human Resources Business Partner & Operations',
        telemetry: 'Joined Telemetry Sports in 2016.',
        education: 'Indiana State University',
        about: 'I have a unique perspective of Telemetry Sports, given that the Founder and CEO of the company is my husband. Because of that, we work extra hard to protect our employees and the integrity of the company. The company has been built on a strong foundation of dedication, hard work and perseverance. I am very proud to be associated with Telemetry Sports and love watching our company thrive and grow.',
        hobbies:
            "Most recently, I read Heart Bones by Colleen Hoover and as a former soccer player, I'm learning to play a whole new sport: tennis!",
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/amyh.png',
    },
    {
        name: 'Wyatt Klueber',
        title: 'Sr. Product Engineer',
        telemetry: 'Joined Telemetry Sports in 2019.',
        education: 'Purdue University',
        about: 'I was drawn to Telemetry Sports as a company because they enable and encourage the team to learn and build within a culture of innovation. Every day, I create and improve products for client needs and my role has allowed me to wear many hats and develop a diverse skillset that lets me impact anywhere on the tech stack. Their trust in me early on in my career helped me develop my skillset into what it is today.',
        hobbies:
            "My favorite read is Firestarter by Stephen King (probably read alongside my tabby cat named Floyd) and I'm a big Pacers basketball fan.",
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/wyattk.png',
    },
    {
        name: 'Cody Steiner',
        title: 'Sr. Full Stack Developer',
        telemetry: 'Joined Telemetry Sports for two years as a summer intern and started full-time in 2022.',
        education: 'Rose-Hulman Institute of Technology',
        about: "I plan and design a wide range of software components and features from the data and structure to our product's UI. The impact and capabilities of software and technology has had a large influence on my interest in the tech space, which has helped me to continue to learn and understand how to contribute. Blended with my passion for football and competition, Telemetry Sports has provided me an opportunity to make a difference in the sports data technology arena.",
        hobbies:
            "I'm a big Illinois basketball fan and some of my favorite hobbies include skiing, golfing, basketball, fantasy football, and March Madness.",
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/codys.png',
    },
    {
        name: 'Ben Goldstein',
        title: 'Sr. Full Stack Developer',
        telemetry: 'Telemetry Sports intern for two years; joined the team full time in 2022.',
        education: 'Rose-Hulman Institute of Technology',
        about: "Some of my early career influences came from my college professors. Even today while I've learned so much from my coworkers, my thought process still defaults to my college training. I design and write code to support all of Telemetry Sports' products and clients, from the database to the UI.",
        hobbies:
            "I grew up in St. Louis and am a big fan of any sports team from there. If I'm on the road, I'm listening to the “Always Sunny” podcast and I'm most likely on my way to a round of disc golf - I played over 200 rounds last year.",
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/beng.png',
    },
    {
        name: 'Clay Troyer',
        title: 'Operations and Product Development',
        telemetry: 'Joined Telemetry Sports in 2022.',
        education: 'DePauw University',
        about: 'Being a lover of sports and retired college athlete, joining a team like Telemetry was an easy choice. From the impressive team environment and leadership to the valuable insights we provide for clients, I really enjoy working at Telemetry and the work that we do. My typical work week consists of about 80 percent software development and 20 percent business development/sales/marketing giving me the freedom to make an impact in multiple disciplines.',
        hobbies:
            "My favorite sport is soccer, and my favorite team is Tottenham Hotspur FC. I've recently taken up Olympic weightlifting and often listen to fitness and health-based podcasts during my commute.",
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/clayt.png',
    },
    {
        name: 'Willie Bowman',
        title: 'Data Engineer',
        telemetry: 'Joined Telemetry Sports in 2023.',
        education: 'Rose-Hulman Institute of Technology',
        about: 'I’ve always had a knack for two things: math and sports. I was always a mathematician for elementary school career days and went on to earn a math degree. I played 8 sports growing up and went on to be a collegiate basketball player. I married my passions when I joined Telemetry Sports, and I use that passion to invent new ways of using math and data to enhance the games I love.',
        hobbies:
            'I still shoot hoops when I can, and I love watching sports in general. Also a huge country music fan. I love going to concerts and music festivals, and my favorite artists are Chris Stapleton and Tyler Childers.',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/willieb.png',
    },
    {
        name: 'Jay Hood',
        title: 'Sales Rep & Product Expert',
        telemetry: 'Joined Telemetry Sports in 2023.',
        education: 'Ohio Wesleyan University & Bowling Green State University',
        about: 'I spent 37 years coaching college football across the country at every level (DI, FCS, DII, NAIA, and DIII). I love the game of football and I love how Telemetry can help coaches and personnel improve their programs.',
        hobbies: 'I enjoy hiking in State and National Parks, reading, and a great walk on the golf course.',
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/jayh.png',
    },
    {
        name: 'Brayden  Edwards',
        title: 'Sales Rep & Financial Analyst',
        telemetry: 'Joined Telemetry Sports in 2023.',
        education: 'University of Evansville',
        about: "The main reason I like Telemetry Sports is the collaboration that goes on every day. It's rare in this world that everyone can wake up and go to a job where their voice and thoughts matter. The other reason I love Telemetry is because I truly feel our product can help our clients and make their lives easier. Having a product I believe in is the reason I get up and do this every day.",
        hobbies:
            "I love playing CCA sports (Indianapolis-based social sports leagues). Basketball, cornhole, volleyball, you name it, I've probably played in a league. I also enjoy spending time with family/friends and, especially, my fiancè. Outside of that I love to watch sports, travel, and hang out in Broad Ripple.",
        headshot: 'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/headshots/braydene.png',
    },
];

export default employees;
